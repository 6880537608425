import React, { createContext, useState } from "react";

export const DAFOverviewContext = createContext();

export const DAFOverviewProvider = ({ children }) => {
  const [DafOverview, setDafOverview] = useState(null);
  const [activeStep, setActiveStep] = useState(1);
  const [mockInterviewData, setMockInterviewData] = useState([]);
  const [recordedVideo, setRecordedVideo] = useState(null);

  return (
    <DAFOverviewContext.Provider value={{ DafOverview, setDafOverview, activeStep, setActiveStep, mockInterviewData, setMockInterviewData, recordedVideo, setRecordedVideo }}>
      {children}
    </DAFOverviewContext.Provider>
  );
};
