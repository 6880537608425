import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import './index.css';
import UserContext from "../../UserContext"; 
import {DAFOverviewContext} from "../../DAFOverviewContext";
import { useNavigate } from 'react-router-dom';


export default function DafOverview() {
  const { DafOverview, setDafOverview, setActiveStep } = useContext(DAFOverviewContext);
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const navigate = useNavigate();
  const [academicFields, setAcademicFields] = useState({
    state: '',
    district: '',
    stream: '',
    optionalSubjects: '',
  });

  const [personalFields, setPersonalFields] = useState({
    prizes: '',
    sports: '',
    leadershipPosition: '',
    extracurricularActivities: '',
  });

  useEffect(() => {
    if (DafOverview) {
      const {  personal_interests } = DafOverview;
      setAcademicFields({
        state: DafOverview.state || '',
        district: DafOverview.district || '',
        stream: DafOverview.stream || '',
        optionalSubjects: DafOverview.optional_subject || '',
      });
      setPersonalFields({
        prizes: personal_interests.prizes || '',
        sports: personal_interests.sports || '',
        leadershipPosition: personal_interests.leadershipPosition || '',
        extracurricularActivities: personal_interests.extracurricular_activities || '',
      });
    }
  }, [DafOverview]);

  const handleAcademicChange = (e) => {
    const { name, value } = e.target;
    setAcademicFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePersonalChange = (e) => {
    const { name, value } = e.target;
    setPersonalFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const payload = {
      academicFields,
      personalFields,
    };

    // setActiveStep(4);
    setDafOverview(payload);
    const userId = loggedInUserDetails.user_id;
    navigate('/ai-interview', {
      state: { userId },
    });

  };

  return (
    <div className="daf-main-container">
      <div className="daf-overview">
        <div className="section-header">Your DAF Overview</div>
        <div className="fields-container">
          <div className="field-wrapper">
            <div className="field-label-ai-mock">State</div>
            <input
              type="text"
              name="state"
              value={academicFields.state}
              onChange={handleAcademicChange}
              className="input-field-ai-mock"
            />
          </div>
          <div className="field-wrapper">
            <div className="field-label-ai-mock">District</div>
            <input
              type="text"
              name="district"
              value={academicFields.district}
              onChange={handleAcademicChange}
              className="input-field-ai-mock"
            />
          </div>
          <div className="field-wrapper">
            <div className="field-label-ai-mock">Stream</div>
            <input
              type="text"
              name="stream"
              value={academicFields.stream}
              onChange={handleAcademicChange}
              className="input-field-ai-mock"
            />
          </div>
          <div className="field-wrapper">
            <div className="field-label-ai-mock">Optional Subjects</div>
            <input
              type="text"
              name="optionalSubjects"
              value={academicFields.optionalSubjects}
              onChange={handleAcademicChange}
              className="input-field-ai-mock"
            />
          </div>
        </div>

        <div className="section-header">Personal Interest</div>
        <div className="fields-container">
          <div className="field-wrapper">
            <div className="field-label-ai-mock">Prizes</div>
            <input
              type="text"
              name="prizes"
              value={personalFields.prizes}
              onChange={handlePersonalChange}
              className="input-field-ai-mock"
            />
          </div>
          <div className="field-wrapper">
            <div className="field-label-ai-mock">Sports</div>
            <input
              type="text"
              name="sports"
              value={personalFields.sports}
              onChange={handlePersonalChange}
              className="input-field-ai-mock"
            />
          </div>
          <div className="field-wrapper">
            <div className="field-label-ai-mock">Leadership Position</div>
            <input
              type="text"
              name="leadershipPosition"
              value={personalFields.leadershipPosition}
              onChange={handlePersonalChange}
              className="input-field-ai-mock"
            />
          </div>
          <div className="field-wrapper">
            <div className="field-label-ai-mock">Extracurricular Activities</div>
            <input
              type="text"
              name="extracurricularActivities"
              value={personalFields.extracurricularActivities}
              onChange={handlePersonalChange}
              className="input-field-ai-mock"
            />
          </div>
        </div>
      </div>

      <div className="submit-container">
        <button className="submit-button-ai-mock" onClick={handleSubmit}>
          Start Interview
        </button>
      </div>
    </div>
  );
}
