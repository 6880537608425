import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HiMenuAlt2 } from "react-icons/hi";
import UserContext from "../../UserContext";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import FastForwardIcon from "@mui/icons-material/FastForward";
// import AccountTreeIcon from "@mui/icons-material/AccountTree";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import AssistantIcon from "@mui/icons-material/Assistant";
// import PersonIcon from "@mui/icons-material/Person";
import { useSidebar } from "../../NavContext";
import {
   styled, 
  //  useTheme 
  } from "@mui/material/styles";
import Button from "@mui/material/Button";
import premiunIcon from "./premiumIcon.png";
import HeaderBanner from "../HeaderBanner/HeaderBanner";
import BannerContext from "../../bannerContext";
import { MegaphoneIcon, XMarkIcon } from "@heroicons/react/24/outline";
import bannerImg1 from '../Images/bannerImg1.png';
import bannerImg2 from '../Images/bannerImg2.png';
import rightIcon from '../Images/rightIcon.png';



import "./index.css";
// import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";

const BuyButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#20B486",
  color: "white",
  borderRadius: "6px",
  padding: "4px ",
  minHeight: "30px",
  fontFamily: "Roboto, sans-serif",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: 2,
  border: "1px solid rgba(31, 91, 67, 0.1)",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "rgba(228, 255, 224, 0.9)",
  },
}));

function Header() {
  const { sidebarOpen, setSidebarOpen, toggleSidebar } = useSidebar();
  const [loggedInUserDetails] = useContext(UserContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const [bannerData, setBannerData] = useContext(BannerContext);
  const handleDropdownToggle = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleNavigation = (path) => {
    setDropdownOpen(false);
    navigate(path);
  };

  useEffect(() => {
    // setBannerData({
    //   showBanner: "true",
    // });
    const closeDropdown = (e) => {
      if (!e.target.closest(".n-navbar-userProfile")) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", closeDropdown);
    return () => document.removeEventListener("mousedown", closeDropdown);
  }, []);
  const handleRemoveBanner = () => {
    setBannerData({
      showBanner: "false",
    });

    localStorage.setItem("showBanner", false);
  };
  const handlePromotionClick = (event) => {
    navigate("/pricing");
  };
  return (
    <>
    {/* <div className="flex flex-col justify-center items-center w-[100vw] h-fit"> */}
    {/* {(loggedInUserDetails.premium && bannerData.showBanner === "true" ) &&
      <HeaderBanner handleRemoveBanner={handleRemoveBanner} />
    } */}
    <header className="n-navbar-header">
    {(!loggedInUserDetails.premium && bannerData.showBanner === "true") && (
  <div 
    className="w-[100vw] ml-[100px] h-[46px] py-1.5 bg-[#23b279] justify-center items-center gap-[60px] flex"
    onClick={handlePromotionClick}
  >
    <div className="justify-center items-center gap-8 flex ml-9">
      <div className="justify-start items-center gap-2 flex">
        <div>
          <span className="text-white text-base font-normal font-['Manrope'] leading-relaxed">Use code  </span>
          <span className="text-white text-base font-medium font-['Manrope'] underline leading-relaxed">"GETEDGEINMAINS"</span>
          <span className="text-white text-base font-normal font-['Manrope'] leading-relaxed"> to get Special discount</span>
        </div>
        <div className="justify-start items-center gap-2.5 flex">
          <img className="w-6 h-6" src={bannerImg1} alt="Banner 1"/>
          <img className="w-6 h-6" src={bannerImg2} alt="Banner 2"/>
        </div>
      </div>
      <div className="px-3 py-1 bg-white/10 rounded-full justify-center items-center flex cursor-pointer">
        <div className="pr-2 justify-center items-center flex">
          <div className="text-white text-base font-semibold font-['Manrope'] leading-relaxed">Check Now</div>
        </div>
        <div className="w-7 h-6 pl-[1.67px] pr-[3.33px] pt-[5px] pb-[4.90px] justify-center items-center flex">
          <img className="w-7 h-6" src={rightIcon} alt="Right icon"/>
        </div>
      </div>
    </div>
    <div className="flex justify-end w-7">
      <button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          handleRemoveBanner(); 
        }}
        className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
      >
        <span className="sr-only">Dismiss</span>
        <XMarkIcon className="h-5 w-5 text-gray-900" aria-hidden="true" />
      </button>
    </div>
  </div>
)}
      <div className="n-nav-container ">
      <div className="n-navbar-logoContainer">
        <div className="n-navbar-homeIconWrapper">
          <HiMenuAlt2 onClick={toggleSidebar} size={30} />
        </div>
        <Link to="/">
          <img
            src="/images/mainLogo.png"
            alt="collectorbabu-logo"
            className="collectorbabu-logo"
          />
        </Link>
      </div>
      <nav className="n-navbar-navigationContainer">
          <PlanBadge 
          loggedInUserDetails={loggedInUserDetails} 
          premiunIcon={premiunIcon} 
        />
        <button
          onClick={() => navigate("/dashboard")}
          className="n-navbar-dashboardButton"
        >
          Dashboard
        </button>
        {loggedInUserDetails.plan !== "zenith_combo" &&
          loggedInUserDetails.plan !== "zenith" && (
            <BuyButton onClick={() => navigate("/pricing")}>Buy</BuyButton>
          )}
        <div className="n-navbar-userProfile">
        {loggedInUserDetails.user_id ? (
          <div
            className="n-navbar-profileContainer">
            <div className="n-navbar-profileImageWrapper">
              <Link to="/profile">
                <img
                  className="profile-image dropbtn"
                  src={loggedInUserDetails.picture}
                  alt="profile"
                />
              </Link>
            </div>
          </div>
          
          ) : (
            <BuyButton onClick={() => navigate("/login")}>Login</BuyButton>
            )}
         
          {dropdownOpen ? (
            <RiArrowDropUpLine size={30} onClick={handleDropdownToggle} />
          ) : (
            <RiArrowDropDownLine size={30} onClick={handleDropdownToggle} />
          )}
          {dropdownOpen && (
            <div className="n-navbar-dropdownMenu">
              <Link style={{ textDecoration: "none", color: "black"}} to="/upsc-mains-2024-essay-gs-questions-model-answers">
                <WorkOutlineIcon /> Mains 2024
              </Link>
              <Link  style={{ textDecoration: "none", color: "black"}} to="/upsc-mains-2023-essay-gs-questions-model-answers">
                <WorkOutlineIcon /> Mains 2023
              </Link>
              <Link  style={{ textDecoration: "none", color: "black"}} to="/upsc-notes-zero">
                <BookmarkBorderOutlinedIcon /> Notes Zero
              </Link>
              <Link style={{ textDecoration: "none", color: "black"}} to="/pyq-fast-track">
                <FastForwardIcon /> PYQ Fast track
              </Link>
              {/* <Link to="/upsc-ias-mock-interview"><PersonIcon /> Mock Interview</Link> */}
              {/* <Link to="/pyqexplorer"><FastForwardIcon />PYQ Explorer</Link> */}
              <Link style={{ textDecoration: "none", color: "black"}} to="/news">
                <AssistantIcon /> The Desi Explainers
              </Link>
              {/* <Link style={{ textDecoration: "none", color: "black"}} to="/upsc-dashboard">
                <AccountTreeIcon /> UPSC Dashboard
              </Link> */}
              {/* <Link style={{ textDecoration: "none", color: "black"}} to="/savedforlater">
                <BookmarkBorderOutlinedIcon /> Saved For Later
              </Link> */}
            </div>
          )}
        </div>
      </nav>
      </div>
    </header>
    {/* </div> */}
    </>
  );
}

const PlanBadge = React.memo(({ loggedInUserDetails }) => {
  const planNameMap = {
    trial: "Trial Plan",
    basic: "Basic",
    zenith: "Zenith", 
    zenith_combo: "Zenith Combo"
  };

  if (!loggedInUserDetails?.premium) return null;

  const planName = planNameMap[loggedInUserDetails.plan];
  
  return (
    <div className="justify-start items-center gap-1.5 inline-flex h-[42px]">
      <img 
        src={premiunIcon} 
        alt="Premium" 
        className="w-[42px] h-[42px]" 
      />
      <div className="flex flex-col justify-center items-center">
        {planName && (
          <div className="flex items-center justify-center h-fit p-0 w-full gap-1">
            <p className="p-0 my-auto text-center text-black text-base font-normal font-['Roboto'] leading-snug">{planName}</p>
            {loggedInUserDetails.Subs_year && (
              <p className="p-0 my-auto text-center text-black text-base font-normal font-['Roboto'] leading-snug">'{String(loggedInUserDetails.Subs_year).slice(-2)}</p>
              // <p className="p-0 my-auto text-center text-black text-base font-normal font-['Roboto'] leading-snug">'{'2030'.slice(-2)}</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
});

export default Header;
