import { useEffect, useRef, useCallback, useState, useContext } from "react";

import { RealtimeClient } from "@openai/realtime-api-beta";
import { ItemType } from "@openai/realtime-api-beta/dist/lib/client.js";
import { WavRecorder, WavStreamPlayer } from "../lib/wavtools/index.js";
import { DAFOverviewContext } from "../../../DAFOverviewContext";
import UserContext from '../../../UserContext';
import { FaVideo } from "react-icons/fa6";
import { FaVideoSlash } from "react-icons/fa6";
import { IoMdMicOff } from "react-icons/io";
import { IoMdMic } from "react-icons/io";
import axios from 'axios';
import AudioPlayer from "../../AudioPlayer/AudioPlayer";
import "./ConsolePage.scss";
import "./index.css";
import { useLocation, useNavigate } from "react-router-dom";

import Navbar from "../../Navbar";
// import Step5 from '../../AIMock/step5.js'

//Main relay server
const LOCAL_RELAY_SERVER_URL = "wss://collectorbabu.com:8001";

//Local relay server
// const LOCAL_RELAY_SERVER_URL = "ws://localhost:8081";

// console.log("REACT_APP_LOCAL_RELAY_SERVER_URL_FINAL", process.env.REACT_APP_LOCAL_RELAY_SERVER_URL);
// console.log("REACT_APP_LOCAL_RELAY_SERVER_URL",LOCAL_RELAY_SERVER_URL)
interface RealtimeEvent {
  time: string;
  source: "client" | "server";
  count?: number;
  event: { [key: string]: any };
}

export function ConsolePage() {
const location = useLocation();
const { userId } = location.state || {};
// console.log(userId);
  const navigate = useNavigate();
  const {
    DafOverview,
    setDafOverview,
    setActiveStep,
    mockInterviewData,
    setMockInterviewData,
    recordedVideo,
    setRecordedVideo,
  } = useContext(DAFOverviewContext);





  const apiKey = LOCAL_RELAY_SERVER_URL || "";
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient(
      LOCAL_RELAY_SERVER_URL
        ? { url: LOCAL_RELAY_SERVER_URL }
        : {
            apiKey: apiKey,
            dangerouslyAllowAPIKeyInBrowser: true,
          }
    )
  );

  const startTimeRef = useRef<string>(new Date().toISOString());

  const [items, setItems] = useState<ItemType[]>([]);
  const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
  const [isConnected, setIsConnected] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [rapidFireQuestion,setRapidFireQuestion] = useState<string[]>([]);

  /**
   * Connect to conversation:
   * WavRecorder taks speech input, WavStreamPlayer output, client is API client
   */
  const connectConversation = useCallback(async () => {

    await getMockQuestionsData();
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    // Set state variables
    startTimeRef.current = new Date().toISOString();
    setIsConnected(true);
    setRealtimeEvents([]);
    setItems(client.conversation.getItems());

    // Connect to microphone
    await wavRecorder.begin();

    // Connect to audio output
    await wavStreamPlayer.connect();

    changeTurnEndType();

    // Connect to realtime API
    await client.connect();
    client.sendUserMessageContent([
      {
        type: `input_text`,
        text: `hello`,
        // text: `For testing purposes, I want you to list ten car brands. Number each item, e.g. "one (or whatever number you are one): the item name".`
      },
    ]);

    if (client.getTurnDetectionType() === "server_vad") {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
  }, []);

  const formatToParagraph = () => {
    if (!DafOverview) return "";

    const { academicFields, personalFields } = DafOverview;

    const academicParagraph = `
      The candidate is from the state of ${academicFields.state}, district ${academicFields.district}. 
      They have pursued ${academicFields.stream} and their optional subject is ${academicFields.optionalSubjects}.
    `;

    const personalParagraph = `
      In terms of personal interests, the candidate enjoys ${
        personalFields.sports
      } and is involved in ${personalFields.extracurricularActivities}.
      They have held leadership positions: ${
        personalFields.leadershipPosition || "N/A"
      } and have received the following prizes: ${
      personalFields.prizes || "N/A"
    }.
    `;

    return `${academicParagraph} ${personalParagraph}`;
  };

    const getMockQuestionsData = async () => {
      if (!userId) return '';
    
      let text = `${DafOverview?.academicFields?.stream || ''} ${DafOverview?.academicFields?.optionalSubjects || ''} ${DafOverview?.personalFields?.extracurricularActivities || ''} ${DafOverview?.personalFields?.leadershipPosition || ''} ${DafOverview?.personalFields?.prizes || ''} ${DafOverview?.personalFields?.sports || ''}`;

    
      let body = {
        user_id: userId,
        text: text,
      };

      try {
        let responseMockQuestions = await axios.post(
          "https://collectorbabu.com/api/interview/mock_questions",
          body,
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
            },
          }
        );

  
  
        const mockQuestions = responseMockQuestions.data.mock_questions;

    let exact_match = mockQuestions.exact_match.map((topic: string) => topic.toLowerCase());
    let selectedQuestions = [];

    const filteredQuestions = mockQuestions.questions
    .filter((question: { topic: string }) => exact_match.includes(question.topic.toLowerCase()))
    .map((question: { question: string }) => question.question);

    if (filteredQuestions.length > 0) {
      const shuffledQuestions = filteredQuestions.sort(() => 0.5 - Math.random());
      selectedQuestions = shuffledQuestions.slice(0, 10);
    }
     setRapidFireQuestion(selectedQuestions);
      } catch (error) {
        console.log(error)
      }
    };



const Instructions = `Act as a strict, old-aged UPSC Personality Test interviewer who seems irritated after conducting day-long interviews and uses a Hinglish accent (though the language is strictly English-only). Your goal is to ask "Wow" type questions that are short, smart, and designed to prompt powerful responses, directly inspired by the candidate's Detailed Application Form (DAF). You should emulate the directness of an academic viva.

Your knowledge is deep in at least two different areas mentioned in the DAF, so feel free to make clever, surprising connections between topics. Use this to add an element of surprise to questions. Follow-up questions are encouraged and should be used unpredictably to keep the candidate focused and on their toes. Follow-ups should be very tough and in direct harsh tone. Aim to cover a wide breadth of topics.

Keep your tone conversational and natural, balancing formal and informal styles as with real-life interviews—make it sound spontaneous. Your task is to be friendly, yet strict and surprising. Remember to not appear polite, be strict.

# Specific Questions:
Before moving to dynamic questions based on the DAF, start by asking questions very similar to these specific ten questions in the same order with technical follow-up question if needed:
${rapidFireQuestion}
# Additional Instructions:
- These ten questions must be asked **first** in the same order one by one, regardless of the candidate's responses.
- After completing the ten questions, proceed to dynamic, spontaneous questions based on the DAF.

# General Instructions:
- Keep questions very short, just as they would be in a viva.
- Mix up the questions to cover multiple areas (breadth) while sometimes delving deeper (depth).
- Speak with a Hinglish tone (but English language): slightly nagging, yet casual and relatable.
- Never elaborate on or praise the candidate's answers; simply move on to the next question.
- Avoid passing comments on responses. Keep the student guessing whether you liked or disliked the response.

DAF :- ${formatToParagraph()}`;

  /**
   * Disconnect and reset conversation state
   */
  const disconnectConversation = useCallback(async () => {
    setIsConnected(false);
    setRealtimeEvents([]);
    setItems([]);
    setDafOverview(null);

    const client = clientRef.current;
    client.disconnect();

    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();
    // setActiveStep(5);
    // navigate("/ai-mock");
  }, []);

  /**
   * Switch between Manual <> VAD mode for communication
   */
  const changeTurnEndType = async () => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;

    // Always use server_vad for automatic turn detection
    client.updateSession({ turn_detection: { type: "server_vad" } });

    // Start recording if connected
    if (client.isConnected()) {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
  };

  /**
   * Auto-scroll the conversation logs
   */
  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll("[data-conversation-content]")
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  useEffect(() => {
    if (items.length > 0) {
      const conversationData = items.map((item) => ({
        role: item.role,
        content: item.formatted.transcript || item.formatted.text || "",
      }));
      setMockInterviewData(conversationData);
    }
  }, [items, setMockInterviewData]);


  useEffect(() => {
    // Get refs
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    //Set Voice
    // Array of voices
    const voices = [
      "ash",
      "ballad",
      "verse",
    ];

    // Select a random voice
    const randomVoice = voices[Math.floor(Math.random() * voices.length)] as
      | "ash"
      | "ballad"
      | "verse";

    // Set Voice
    client.updateSession({ voice: randomVoice });

    // Set instructions
    client.updateSession({ instructions: Instructions });
    // Set transcription, otherwise we don't get user transcriptions back
    client.updateSession({ input_audio_transcription: { model: "whisper-1" } });

    // handle realtime events from client + server for event logging
    client.on("realtime.event", (realtimeEvent: RealtimeEvent) => {
      setRealtimeEvents((realtimeEvents) => {
        const lastEvent = realtimeEvents[realtimeEvents.length - 1];
        if (lastEvent?.event.type === realtimeEvent.event.type) {
          // if we receive multiple events in a row, aggregate them for display purposes
          lastEvent.count = (lastEvent.count || 0) + 1;
          return realtimeEvents.slice(0, -1).concat(lastEvent);
        } else {
          return realtimeEvents.concat(realtimeEvent);
        }
      });
    });
    client.on("error", (event: any) => console.log(event));
    client.on("conversation.interrupted", async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });
    client.on("conversation.updated", async ({ item, delta }: any) => {
      const items = client.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
        setParticipants((prevParticipants) => {
          const randomIndex = Math.floor(Math.random() * prevParticipants.length);
          return prevParticipants.map((participant, index) => ({
            ...participant,
            isTalking: index === randomIndex,
          }));
        });
      }
      if (item.status === "completed" && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }
      
      setItems(items);
    });
    setItems(client.conversation.getItems());
    setParticipants((prevParticipants) =>
      prevParticipants.map((participant) => ({
        ...participant,
        isTalking: false,
      }))
    );

    return () => {
      // cleanup; resets to defaults
      client.reset();
    };
  }, [rapidFireQuestion]);

  // const [recordedVideo, setRecordedVideo] = useState<string | null>(null);
  const recordedChunksRef = useRef<Blob[]>([]);

  // const startRecording = async () => {
  //   try {
  //     // Get screen stream with system audio
  //     const screenStream = await navigator.mediaDevices.getDisplayMedia({
  //       video: true,
  //       audio: true, // System audio
  //     });

  //     // Get user audio (microphone)
  //     const micStream = await navigator.mediaDevices.getUserMedia({
  //       audio: true,
  //     });

  //     // Create an audio context to mix audio tracks
  //     const audioContext = new AudioContext();
  //     const destination = audioContext.createMediaStreamDestination();

  //     // Connect screen audio (if available)
  //     if (screenStream.getAudioTracks().length > 0) {
  //       const screenAudioSource = audioContext.createMediaStreamSource(screenStream);
  //       screenAudioSource.connect(destination);
  //     }

  //     // Connect microphone audio
  //     const micAudioSource = audioContext.createMediaStreamSource(micStream);
  //     micAudioSource.connect(destination);

  //     // Combine video tracks from the screen with mixed audio tracks
  //     const combinedStream = new MediaStream([
  //       ...screenStream.getVideoTracks(),
  //       ...destination.stream.getAudioTracks(),
  //     ]);

  //     // Set up MediaRecorder
  //     mediaRecorderRef.current = new MediaRecorder(combinedStream, {
  //       mimeType: "video/webm; codecs=vp8,opus",
  //     });

  //     // Handle recorded data
  //     recordedChunksRef.current = [];
  //     mediaRecorderRef.current.ondataavailable = (event) => {
  //       if (event.data.size > 0) recordedChunksRef.current.push(event.data);
  //     };

  //     // Stop recording
  //     mediaRecorderRef.current.onstop = () => {
  //       const blob = new Blob(recordedChunksRef.current, { type: "video/webm" });
  //       setRecordedVideo(URL.createObjectURL(blob));
  //     };

  //     // Start recording
  //     mediaRecorderRef.current.start();
  //     setRecording(true);
  //   } catch (err) {
  //     console.error("Error starting screen recording:", err);
  //   }
  // };

  // const stopRecording = () => {
  //   mediaRecorderRef.current?.stop();
  //   setRecording(false);
  // };


  const startRecording = async () => {
    try {
      // Get screen stream with system audio
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true, // System audio (if supported)
      });

      // Get microphone audio
      const micStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      // Create an audio context to handle synchronization
      const audioContext = new AudioContext();
      const destination = audioContext.createMediaStreamDestination();

      // Process screen audio (if available)
      if (screenStream.getAudioTracks().length > 0) {
        const screenAudioSource = audioContext.createMediaStreamSource(screenStream);

        // Add delay for synchronization if needed
        const screenDelay = audioContext.createDelay();
        screenDelay.delayTime.value = 0.1; // Adjust delay (in seconds) for alignment
        screenAudioSource.connect(screenDelay).connect(destination);
      }

      // Process microphone audio
      const micAudioSource = audioContext.createMediaStreamSource(micStream);

      // Add delay for microphone to sync with system audio
      const micDelay = audioContext.createDelay();
      micDelay.delayTime.value = 0.1; // Adjust delay (in seconds) for alignment
      micAudioSource.connect(micDelay).connect(destination);

      // Combine video tracks from the screen with mixed and synchronized audio
      const combinedStream = new MediaStream([
        ...screenStream.getVideoTracks(),
        ...destination.stream.getAudioTracks(),
      ]);

      // Set up MediaRecorder
      mediaRecorderRef.current = new MediaRecorder(combinedStream, {
        mimeType: "video/webm; codecs=vp8,opus",
      });

      // Handle recorded data
      recordedChunksRef.current = [];
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) recordedChunksRef.current.push(event.data);
      };

      // Stop recording
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(recordedChunksRef.current, { type: "video/webm" });
        setRecordedVideo(URL.createObjectURL(blob));
      };

      // Start recording
      mediaRecorderRef.current.start();
      setRecording(true);
    } catch (err) {
      console.error("Error starting screen recording:", err);
    }
  };

  const stopRecording = () => {
    mediaRecorderRef.current?.stop();
    setRecording(false);
  };

  const [participants, setParticipants] = useState([
    {
      id: 1,
      name: "S. Sharma",
      imageUrl: "/images/AIInterview/interviewer1.jpg",
      isAudioActive: true,
      gender: "male",
      isTalking: false,
    },
    {
      id: 2,
      name: "Sathya",
      imageUrl: "/images/AIInterview/interviewer4.jpg",
      isAudioActive: true,
      gender: "female",
      isTalking: false,
    },
    {
      id: 3,
      name: "R. Shukla",
      imageUrl: "/images/AIInterview/interviewer2.jpg",
      isAudioActive: false,
      gender: "male",
      isTalking: false,
    },
    {
      id: 4,
      name: "D. Aggarwal",
      imageUrl: "/images/AIInterview/interviewer3.jpg",
      isAudioActive: true,
      gender: "male",
      isTalking: false,
    },
  ]);
  

  const [recordingTime, setRecordingTime] = useState(0);
  
  useEffect(() => {
    const timer = setInterval(() => {
      setRecordingTime((prev) => prev + 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const [stream, setStream] = useState<MediaStream | null>(null);
  const [isVideoActive, setIsVideoActive] = useState(false);

  const startCamera = async () => {
    if (!isRecording) {
      setIsRecording(true);
      const startTime = Date.now();
      const id = setInterval(() => {
        setTimeElapsed(Math.floor((Date.now() - startTime) / 1000));
      }, 1000);
      setIntervalId(id);
    }
    try {
      const videoElement = document.getElementById(
        "live-video"
      ) as HTMLVideoElement | null;
      if (!videoElement) {
        console.error("Video element not found");
        return;
      }

      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false, // Set to true if you also need audio
      });
      videoElement.srcObject = mediaStream;
      setStream(mediaStream);
      setIsVideoActive(true);
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const stopCamera = () => {
    setIsRecording(false);
    if (intervalId !== null) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
    if (stream) {
      stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
      setStream(null);
      setIsVideoActive(false);
    }
  };

  const [recording, setRecording] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);

  const toggleCamera = () => {
    if (isVideoActive) {
      stopCamera();
    } else {
      startCamera();
    }
  };

  const formatTime = (seconds: number) => {
    const hours = String(Math.floor(seconds / 3600)).padStart(2, "0");
    const minutes = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
    const secs = String(seconds % 60).padStart(2, "0");
    return `${hours}:${minutes}:${secs}`;
  };


  return (
    <>
      {/* <Navbar/> */}
      <div
      className="ai-mock-main-container">
        <div className="video-call">
          <div className="participants-grid">
            {participants.map((participant) => (
              <div key={participant.id} className="participant-card">
                <div className="participant-wrapper">
                  <img
                    loading="lazy"
                    src={participant.imageUrl}
                    alt={`Video feed of ${participant.name}`}
                    className="participant-video"
                  />
                  <div className="participant-info">
                    <div className="name-container">
                      <div className="participant-name">{participant.name}</div>
                    </div>
                {participant.isTalking && (
                  <div
                      className="audio-status-single"
                      role="status"
                      aria-label="Audio level indicator"
                      >
                      <span className="ball"></span>
                      <span className="ball"></span>
                      <span className="ball"></span>
                    </div>
                    )}

                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="main-video">
            <div className="video-container">
              <video
                className="video-feed"
                autoPlay
                muted
                playsInline
                id="live-video"
              />
              <div className="recording-status">
                <div className="record-indicator">
                  <div className="record-icon-wrapper">
                    <div className="record-icon" />
                  </div>
                </div>
                <div className="timer">{formatTime(timeElapsed)}</div>
              </div>
              <div className="user-info">
                <div className="user-name">You</div>
                <div
                  className="audio-status"
                  role="status"
                  aria-label="Audio level indicator"
                >
                  <span className="ball"></span>
                  <span className="ball"></span>
                  <span className="ball"></span>
                </div>
              </div>
            </div>
          </div>

          <div className="controls-container">
            <div className="controls-wrapper">
              <button
                className={`ai-connect-button ${
                  isConnected ? "disconnect-button" : "connect-button"
                }`}
                onClick={
                  isConnected ? disconnectConversation : connectConversation
                }
              >
                {isConnected ? "End Interview" : "Start Interview"}
              </button>

              <button className="stop-video-button" onClick={toggleCamera}>
                {isVideoActive ? (
                  <FaVideo size={22} />
                ) : (
                  <FaVideoSlash size={22} />
                )}
              </button>
              <button style={{backgroundColor:"#ff4d4d"}} className="ai-connect-button" onClick={recording ? stopRecording : startRecording}>
                {recording ? "Stop Recording" : "Start Recording"}
              </button>
            </div>
          </div>
        </div>
        <div className="question-container">
          <div className="header-ai-mock">
            <div className="title-ai-mock">Questions</div>
          </div>
          <div className="questions-list" data-conversation-content>
            {!items.length && `awaiting connection...`}
            {items.map((conversationItem, i) => {
              if (i === 0 && conversationItem.role === "user") {
                return null;
              }

              return (
                <div className="question-item" key={conversationItem.id}>
                  <div className={`speaker ${conversationItem.role || ""}`}>
                    <div>
                      {conversationItem.role === "user" && "User"}
                      {conversationItem.role === "assistant" && "Interviewer"}
                    </div>
                  </div>
                  <div className={`speaker-content`}>
                    {conversationItem.type === "function_call_output" && (
                      <div>{conversationItem.formatted.output}</div>
                    )}
                    {!!conversationItem.formatted.tool && (
                      <div>
                        {conversationItem.formatted.tool.name}(
                        {conversationItem.formatted.tool.arguments})
                      </div>
                    )}
                    {!conversationItem.formatted.tool &&
                      conversationItem.role === "user" && (
                        <div>
                          {conversationItem.formatted.transcript ||
                            (conversationItem.formatted.audio?.length
                              ? "(awaiting transcript)"
                              : conversationItem.formatted.text ||
                                "(item sent)")}
                        </div>
                      )}
                    {!conversationItem.formatted.tool &&
                      conversationItem.role === "assistant" && (
                        <div>
                          {conversationItem.formatted.transcript ||
                            conversationItem.formatted.text ||
                            "(truncated)"}
                        </div>
                      )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
