import React, { useState, useContext } from "react";
import axios from "axios";
import './index.css';
import UserContext from "../../UserContext";
import {DAFOverviewContext } from "../../DAFOverviewContext";
import { FilePlus, Trash } from "@phosphor-icons/react";
import { CloudArrowUp } from "@phosphor-icons/react";


export default function UploadDaf() {
  const { DafOverview, setDafOverview, setActiveStep } = useContext(DAFOverviewContext);
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const [uploadStatus, setUploadStatus] = useState("");
  const [loadingDAFUpload, setLoadingDAFUpload] = useState(false);
  const [dafUploadError, setDafUploadError] = useState(false);
  const [dafOverviewData, setDafOverviewData] = useState({});
  const orderOfTableData = [];

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setUploadedFileName(event.target.files[0].name);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      setUploadStatus("Please select a file before uploading.");
      return;
    }

    try {
      const form = new FormData();
      form.append("daf_file", selectedFile);

      setLoadingDAFUpload(true);
      setUploadStatus("Uploading...");

      const response = await axios.post(
        "https://collectorbabu.com/api/interview/daf_upload",
        form,
        {
          params: {
            user_id: loggedInUserDetails.user_id,
          },
          headers: {
            accept: "application/json",
            token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // console.log(sortedTableData);
      // console.log(DafOverview);
      setDafOverview(response.data);
      setDafUploadError(false);
      setActiveStep(3);
      setUploadStatus("Upload successful!");
      setLoadingDAFUpload(false);
    } catch (error) {
      console.error(error);
      setLoadingDAFUpload(false);
      setDafUploadError(true);
      setUploadStatus("Upload failed. Please try again.");
    }
  };

  const handleFileDelete = () => {
    setSelectedFile(null);
    setUploadedFileName("");
    setUploadStatus("");
  };

  return (
    <div className="upload-container-ai-mock">
      <div className="header-section">
        <div className="w-100 flex justify-center">
          <div className="file-icon-plus-container">
            <FilePlus color="#1F5B43" size={36} />
          </div>
        </div>
        <div className="header-title">Upload Your DAF</div>
      </div>

      {uploadedFileName ? (
        <div className="uploaded-file-container">
          <div className="file-details">
            <span>{uploadedFileName}</span>
            <Trash
              size={24}
              color="red"
              className="delete-icon"
              onClick={handleFileDelete}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      ) : (
        <>
          <form className="upload-field" onSubmit={(e) => e.preventDefault()}>
            <CloudArrowUp size={32} />
            <div className="upload-content">
              <div className="upload-description">
                <div className="upload-text">Select a file or drag and drop here</div>
                <div className="file-types">JPG, PNG or PDF, file</div>
              </div>
              <label htmlFor="file-upload" className="visually-hidden">
                Upload file
              </label>
              <button
                type="button"
                className="select-button-ai-mock"
                onClick={() => document.getElementById("file-upload").click()}
              >
                Select File
              </button>
              <input
                id="file-upload"
                type="file"
                accept=".jpg,.png,.pdf"
                className="visually-hidden"
                onChange={handleFileChange}
              />
            </div>
          </form>
        </>
      )}
      
         {uploadedFileName && (
          <button
            className="details-button"
            onClick={handleFileUpload}
            disabled={loadingDAFUpload}
          >
            Submit DAF
          </button>
          )}

      <div className="upload-status">{uploadStatus}</div>
      <div className="interview-text">
        Appearing for State PCS Interview? Just click the button below
      </div>
      <button onClick={()=>{setActiveStep(3)}} className="details-button">Fill Details</button>
    </div>
  );
}
